import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import '@oddcamp/cocoon-vanilla-js'

import { Datepicker } from 'vanillajs-datepicker'
import de from 'vanillajs-datepicker/locales/de'

// Lazy table
import '@sinc/lazy-table/LazyTable'
import '@sinc/lazy-table/TableCount'
// with search box
import '@sinc/search-box'

// Better select
import NiceSelect from 'nice-select2/src/js/nice-select2'

// Components
import ImageSelect from './components/ImageSelect'
customElements.define('image-select', ImageSelect)

import {DefaultConfigurationSelect} from './components/DefaultConfigurationSelect.js'
customElements.define('default-configuration-select', DefaultConfigurationSelect)

import {LazyContent} from './components/LazyContent.js'
customElements.define('lazy-content', LazyContent)

import {ScrollToTop} from './components/ScrollToTop.js'
customElements.define('scroll-to-top', ScrollToTop)

UIkit.use(Icons)

// Initialize navbar
UIkit.navbar('#navbar')
window.UIkit = UIkit

Object.assign(Datepicker.locales, de)
window.Datepicker = Datepicker

window.NiceSelect = NiceSelect
